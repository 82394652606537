import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls, variable) => {
	console.log(prefixCls, 'prefixCls')
	return useRegisterStyle('pagelet-personal-holiday', () => [
		{
			// base
			[`.${prefixCls}`]: {
				color: chartToken.$font_color,
				fontSize: variable.size,
        background: '#fff',
        width: '100%',
        minHeight: '184px',
        '&::after': {
          content: '""', // content不能为空，否则会被忽略
          position: 'absolute'
        },
				['.ant5-tooltip']: {
					left: '79px !important',
    			top: '-40px !important',
				},
				['.ant5-tooltip-arrow']: {
					display: 'none'
				},
        ['.new-home-holiday-card-title-url']: {
          color: 'rgba(0, 0, 0, 0.72)',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        },
        ['.ec-ehr-base-card-title-text']: {
          ['span:first-child']: {
            marginRight: '12px',
          },
          ['span:last-child']: {
            marginTop: '2px',
          },

        },
        ['.new-home-holiday-card-title-url-icon']: {
          marginLeft: '2px',
        },
				['.new-home-holiday-card']: {
          cursor: 'pointer',
					position: 'relative',
					marginTop: '12px',
					padding: '0 16px 22px 16px',
					borderRadius: '8px',
					overflow: 'hidden',
					border: '0.5px solid rgba(255, 100, 0, 0.12)',
					background: 'rgba(255, 255, 255, 0.04)',
					backgroundImage: 'url(//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/holiday-bg-web.png)',
					backgroundPosition: 'center center',/* 水平垂直居中 */
					backgroundRepeat: 'no-repeat',/* 不重复背景图片 */
          backgroundSize: 'cover',
					['&:hover']: {
						border: '0.5px solid rgba(255, 100, 0)',
					},
					['.new-home-holiday-card-tip']: {
						position: 'absolute',
						right: '0',
						top: '0',
						padding: '0 8px',
						lineHeight: '20px',
						fontFamily: 'PingFang SC',
						fontSize: '12px',
						fontWeight: '500',
						lineHeight: '20px',
						letterSpacing: '0em',
						fontVariationSettings: '"opsz" auto',
						borderRadius: '0px 8px 0px 12px',
						color: '#FF6400',
						background: 'rgba(255,255,255,0.8)',
					},
					['.new-home-holiday-card-img']: {
						position: 'absolute',
						right: '-3px',
						bottom: '-4px',
						width: '44px',
						height: '44px',
						['& > img']: {
							display: 'block',
							width: '100%',
							height: '100%',
						},
					},
					['.new-home-holiday-card-members']: {
						marginTop: '30px',
						display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '6px',
						['.new-home-holiday-card-members-datial']: {
              textAlign: 'center',
							['.new-home-holiday-card-members-datial-top']: {
								marginBottom: '4px',
								fontFamily: 'DIDI FD',
								fontSize: '32px',
								fontWeight: '900',
								lineHeight: '40px',
								letterSpacing: '0px',
								color: 'rgba(0, 0, 0, 0.7)',
							},
              ['.new-home-holiday-card-members-datial-top-visible']:{
                marginBottom: '4px',
                fontFamily: 'PingFang SC',
								fontSize: '24px',
								lineHeight: '40px',
								letterSpacing: '0px',
								color: 'rgba(0, 0, 0, 0.7)',
              },
              ['.new-home-holiday-card-members-datial-annualNotUsedDays']: {
                color: '#FF6400',
              },
							['.new-home-holiday-card-members-datial-bottom']: {
								fontFamily: '苹方-简',
								fontSize: '12px',
								fontWeight: 'normal',
								lineHeight: '18px',
								letterSpacing: '0px',
								color: 'rgba(0, 0, 0, 0.84)',
							}
						}
					},
					['.new-home-holiday-card-mark']: {
						marginTop: '8px',
						fontFamily: '苹方-简',
						fontWeight: '400',
						lineHeight: '18px',
						fontSize: '12px',
						color: 'rgba(0, 0, 0, 0.72)',
						letterSpacing: '0px',
						fontVariationSettings: '"opsz" auto',
            ['.new-home-holiday-card-mark-name']:{
              color: '#FF6400',
            },
						['.new-home-holiday-card-mark-number']: {
							fontWeight: '500',
						}
					}
				},
        [`.${prefixCls}-content-err`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '118px',
          justifyContent: 'center',
          background: '#fff',
          ['img']: {
            width: '120px',
          },
          ['.text-item-1']: {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: 0,
            color: '#2D3040',
          },
        }
			},
		}
	]);
};

export default useStyle;
