import React, { useState, useEffect } from 'react';
import { getLocale, ConfigProvider, Tooltip } from '@didi/ec-base';
import PageletApi, { Intl } from '@didi/pagelets-sdk';
import manifest from '../manifest.json';
import PageletServices from './services';
import classNames from 'classnames';
import { DHRCardTitle, DHRCardButton } from '@didi/ec-ehr-base';
import { RightThickOutlined, VisibilityOnOutlined, VisibilityOffOutlined } from '@didi/iconflow/ec';

import useStyle from './style';

const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: { apiHost, locales, auth, headers},
  } = props;
  const newHeaders = {
    ...headers,
    'bricks-function-id': auth?.id,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(auth?.functionName)
  }
  PageletApi.newRequest.init(newHeaders, 'personal-holiday');

  const [holidayData, setHolidayData] = useState(null);
  const [initError, setInitError] = useState(false);
  const [visible, setVisible] = useState(false);
  const holidayVisible = JSON.parse(localStorage.getItem('holidayVisible'));

  useEffect(()=>{
    setVisible(holidayVisible || false);
  },[holidayVisible])

  const handleRequest = async () => {
    try {
      // const data = await PageletServices.initPersonalInfo(apiHost);

      const authId = auth?.id;
      const data = await PageletServices.initPersonalInfo(apiHost,{}, {
        ...headers,
        functionId: authId,
        'bricks-function-id': authId,
      });
      if (data?.code === '100501') {
        setInitError(true);
      } else {
        setHolidayData(data?.data);
      }
    } catch (e) {
      console.log(e);
      setInitError(true);
    }
  };

  // 接口请求示例
  useEffect(() => {
    handleRequest();
  }, []);

  const showHolidayNumber = () => {
    localStorage.setItem('holidayVisible', JSON.stringify(!visible));
    setVisible(!visible);
  }

  const prefixCls = 'pagelet-personal-holiday';
  const variable = { size: '12px' };
  const wrapSSR = useStyle(prefixCls, variable);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const classes = classNames(prefixCls);

  return wrapSSR(
    <ConfigProvider prefixCls="ant5">
    <div className={classes}>
      <DHRCardTitle
        title={<>
          <span>我Di假期</span>
          <span onClick={()=>{showHolidayNumber();}}>
            <Tooltip
              getPopupContainer={trigger => trigger.parentElement}
              title={visible ? "隐藏假期数据" : '显示假期数据'}
              placement="topLeft"
            >
              {
                visible ? <VisibilityOnOutlined cursor="pointer" size={16} /> :
                <VisibilityOffOutlined cursor="pointer" size={16} />
              }
            </Tooltip>
          </span>


        </>}
        // showPrefixTag={false}
        type="grey"
        titleAdded={
          <DHRCardButton onClick={() => {window.open(`${window.location.origin}/home/holiday`, '_blank')}}>
            去请假 <RightThickOutlined cursor="pointer" size={14} className="new-home-holiday-card-title-url-icon"/>
          </DHRCardButton>
        }
      />
      {!initError && <div className="new-home-holiday-card" onClick={() => {window.open(`${window.location.origin}/home/holiday`, '_blank')}}>
        <div className="new-home-holiday-card-tip">
          年假总览
        </div>
        <div className="new-home-holiday-card-img">
          <img src="//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/new-cards/holiday-card-search.png" />
        </div>
        <div className="new-home-holiday-card-members">
          <div className="new-home-holiday-card-members-datial">
            {
              visible ? <div className="new-home-holiday-card-members-datial-top new-home-holiday-card-members-datial-annualNotUsedDays">
                {holidayData?.personalHoliday?.annualNotUsedDays}
              </div> : <div className="new-home-holiday-card-members-datial-top-visible">
                **
              </div>
            }
            <div className="new-home-holiday-card-members-datial-bottom">可休年假</div>
          </div>
          <div className="new-home-holiday-card-members-datial">
            {
              visible ? <div className="new-home-holiday-card-members-datial-top">
                {holidayData?.personalHoliday?.annualUsedDays}
              </div> : <div className="new-home-holiday-card-members-datial-top-visible">
                **
              </div>
            }
            <div className="new-home-holiday-card-members-datial-bottom">已休年假</div>
          </div>
          <div className="new-home-holiday-card-members-datial">
            {
              visible ? <div className="new-home-holiday-card-members-datial-top">
                {holidayData?.personalHoliday?.annualNotReleasedDays}
              </div> : <div className="new-home-holiday-card-members-datial-top-visible">
                **
              </div>
            }
            <div className="new-home-holiday-card-members-datial-bottom">未释放</div>
          </div>
        </div>
        <div className="new-home-holiday-card-mark">
          {
            holidayData?.holidayCountdown?.personalHolidayList?.length > 0 ? (
              <div>
                {holidayData?.holidayCountdown?.personalHolidayList?.map(item => {
                  return (
                    <div>
                      距「<span className="new-home-holiday-card-mark-name">{item.holidayName}</span>」
                      还有 <span className="new-home-holiday-card-mark-number">{item.days}</span> 天
                    </div>)
                })}
              </div>
            )
            :
            (
              <div>{holidayData?.holidayCountdown?.defaultMsg}</div>
            )
          }
        </div>

      </div>}
      {initError && <div className={`${prefixCls}-content-err`}>
        <img
          src='//img-hxy021.didistatic.com/static/ehr_static/imgs/DHR/data_anomaly_image.png'
        />
        <span className='text-item-1'>服务不可用，请稍候再试。</span>
      </div>}
    </div>

    </ConfigProvider>
  );
};

export default Pagelet;
